import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, startWith } from 'rxjs';
import { UserButtonComponent } from '../../shared/user-button/user-button.component';
import { YeLogoLightSvg } from '../../svg/ye-logo-light/ye-logo-light.svg';

@Component({
  selector: 'app-navbar-with-logo',
  templateUrl: './navbar-with-logo.component.html',
  styleUrl: './navbar-with-logo.component.sass',
  standalone: true,
  imports: [YeLogoLightSvg],
})
export class NavbarWithLogoComponent {}
