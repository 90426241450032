<nav class="navbar has-viewport-dependent-height has-max-width-100vw is-z-index-navbar" role="navigation" aria-label="main navigation">
  <div class="is-width-viewport is-height-100 is-flex is-align-items-center is-justify-content-end-touch is-relative">

    <div class="navbar-brand">
      <!-- Need to check why routeLink isnt working -->
      <a class="navbar-item" routerLink="/" href="/">
        <svg-ye-logo-light class="is-logo-size"></svg-ye-logo-light>
      </a>
    </div>

  </div>
</nav>